import { RecipeCategory } from '../../../buisnes-object/recipe/RecipeCategory';
import { FormGroup } from '@angular/forms';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-create-categorie',
  templateUrl: './create-categorie.component.html',
  styleUrls: ['./create-categorie.component.scss']
})
export class CreateCategorieComponent implements OnInit {
  public submitted = false;
  public selectedCategories: RecipeCategory[] = [];

  @Input() form: FormGroup;
  @Input() categories: RecipeCategory[];
  @Input() isEdit: boolean;
  @Output() submitEmitter = new EventEmitter<undefined>();
  @Output() cancelEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
    if(this.form.valid){
      this.selectedCategories = this.f.categories.value;
      // this.isEdit = true;
    }
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if(this.form.invalid){
      return;
    } else {
      this.submitEmitter.emit();
      this.submitted = false;
    }
  }

  cancel() {
    this.submitted = false;
    this.cancelEmitter.emit();
  }
}
