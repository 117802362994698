import { Observable, of } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  public login(username: string, password: string): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(username + ':' + password)
    });
    const observable = this.http.post(environment.api + '/usersession' , JSON.stringify([]), { headers });
    return observable.pipe(
      map((result: any) => {
        localStorage.setItem('token', result.token);
        localStorage.setItem('user_id', result.user.user_id);
        return true;
      }),
      catchError((error) => {
        console.log(error);
        return of(false);
      })
    );
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    this.router.navigate(['login'])
  }

  canActivate(): boolean {
    if(localStorage.getItem('token')){
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  public emailResetPassword(email: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable =  this.http.put(environment.api + '/user/password',
    {
      username: email
    },{ headers });
    return observable.pipe(
      map((result: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.logout();
        } else {
          // this.notifyService.showError(error.error.error_message);
        }
        console.log(error);
        return of(false);
      })
    );
  }

  public resetPassword(token: string, password: string): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const observable = this.http.put(environment.api + '/user/resetpassword', JSON.stringify({token: token, password: password}), {headers});
    return observable.pipe(
      map((result: any)=> {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.logout();
        } else if(error.status == 402){
          // this.notifyService.showError('Link abgelaufen');
        } else {
          // this.notifyService.showError(error.error.error_message);
        }
        console.log(error);
        return of(false);
      })
    );
  }
}
