import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/buisnes-object/user/User';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @Input() users: User[];
  @Output() editEmitter = new EventEmitter<User>();
  @Output() resetEmitter = new EventEmitter<User>();
  @Output() deleteEmitter = new EventEmitter<User>();

  constructor() { }

  ngOnInit(): void {
  }

}
