<div id="menu">
    <div class="lbl_bold">Koch & Back Journal</div>
    <div id="menu_items">
        <div class="item" (click)="loadPage('/recipes')">
            <div class="item_lbl lbl_regular" [ngStyle]="{'color': activeMenu == 1 ? 'var(--submit-color)' : 'var(--font-color)'}">Rezepte</div>
            <div [ngStyle]="{'opacity': activeMenu == 1 ? '1' : '0'}" class="item_dot"></div>
        </div>
        <div class="item" (click)="loadPage('/categories')">
            <div class="item_lbl lbl_regular" [ngStyle]="{'color': activeMenu == 2 ? 'var(--submit-color)' : 'var(--font-color)'}">Kategorien</div>
            <div [ngStyle]="{'opacity': activeMenu == 2 ? '1' : '0'}" class="item_dot"></div>
        </div>
    </div>
    <div id="logout_container" (click)="logOut()">
        <img class="icon_user" src="../../../assets/loginuser.svg">
        <div class="lbl_regular">Abmelden</div>
    </div>
</div>
