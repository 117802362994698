import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LoadingService } from 'src/app/service/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  public isLoading = false;
  public loadingSubscription: Subscription

  constructor(
    private loadingScreenService: LoadingService
  ) { }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.pipe(debounceTime(250)).subscribe((value => {
      this.isLoading = value;
    }));
  }

  ngOnDestroy(){
    this.loadingSubscription.unsubscribe();
  }
}
