<div id="list">
  <div class="input_container">
    <img class="icon_input" src="../../../assets/tasksearch.svg">
    <input #search type="text" placeholder="Suchen..." (input)="searchEmitter.emit(search.value)">
  </div>
  <div class="list">
    <div class="lbl_medium">Id</div>
    <div class="lbl_medium">Name</div>
  </div>
  <div class="list_body">
      <div *ngIf="categories.length == 0" class="empty_list lbl_regular">Keine Datensätze</div>
      <app-categorie-list-element *ngFor="let category of categories; let last = last;" [id]="'category_'+category.category_id"
          [last]="last"
          [category]="category"
          (editEmitter)="editEmitter.emit($event)"
          (deleteEmitter)="deleteEmitter.emit($event)"
      ></app-categorie-list-element>
  </div>
</div>
