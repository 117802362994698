import { CategoriesComponent } from './view/categories/categories.component';
import { RecipesComponent } from './view/recipes/recipes.component';
import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './view/login/login.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';
import { LoginService } from './service/login/login.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'recipes',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { isLogin: true }
  },
  {
    path: 'recipes',
    component: RecipesComponent,
    canActivate: [LoginService]
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [LoginService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
