import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  public activeMenu = 1;
  public dialogQuerySubsription: Subscription;

  constructor(
    private lService: LoginService,
    private router: Router,
    private dService: DialogService
  ) {
    this.setSubscriptions();
  }

  ngOnInit(): void {
    this.router.events.subscribe((value) => {
      if(value instanceof NavigationStart){
        this.setMenu(value.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.dialogQuerySubsription.unsubscribe();
  }

  setSubscriptions() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'logout'){
          this.lService.logout();
        }
      }
    });
  }

  setMenu(url: string) {
    switch (url) {
      case '/recipes': {
        this.activeMenu = 1;
        break;
      }
      case '/categories': {
        this.activeMenu = 2;
        break;
      }
      default:{ break; }
    }
  }

  loadPage(page: string) {
    if(this.router.url == page){
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate([page]));
    } else {
      this.router.navigate([page]);
    }
  }

  logOut() {
    this.dService.openQuery(
      {
        title: 'Abmelden',
        message: 'Sind sie sicher, dass Sie sich abmelden wollen?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Abmelden',
        typ: 'logout',
        submit_value: null,
      }
    );
  }
}
