<form *ngIf="!successChanged && !tokenExpired && !invalidToken" class="reset_form" [formGroup]="resetPasswordForm">
    <div class="lbl_bold lbl_title">Neues Passwort</div>
    <div class="lbl_invalid" [ngStyle]="{'opacity': notIdent ? '1' : '0'}">Nicht ident.</div>
    <div class="input_container" [ngClass]="submitted && resetPasswordForm.controls.passwordOne.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/loginpassword.svg">
        <input type="{{showPasswordOne ? 'text' : 'password'}}" placeholder="Passwort" formControlName="passwordOne" (ngModelChange)="notIdent = false">
        <img (click)="showPasswordOne = !showPasswordOne" class='icon_eye' [src]="showPasswordOne ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
    </div>
    <div class="input_container" [ngClass]="submitted && resetPasswordForm.controls.passwordTwo.invalid ? 'input_container_invalid' : ''">
        <img class="icon" src="../../../../assets/loginpassword.svg">
        <input type="{{showPasswordTwo ? 'text' : 'password'}}" placeholder="Passwort wiederholen" formControlName="passwordTwo" (ngModelChange)="notIdent = false">
        <img (click)="showPasswordTwo = !showPasswordTwo" class='icon_eye' [src]="showPasswordTwo ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
      </div>
    <div class="btn_container_horizontal">
      <div class="btn_submit" (click)="onSubmit()">Speichern</div>
    </div>
</form>
<div *ngIf="successChanged && !tokenExpired && !invalidToken" class="reset_form">
    <div class="lbl_bold lbl_title">Passwort erfolgreich gändert.</div>
    <div class="btn_container_horizontal">
        <div class="btn_submit" (click)="toLogin()">Zum Login</div>
    </div>
</div>
<div *ngIf="tokenExpired && !invalidToken" class="reset_form" [ngStyle]="{'align-items': 'center'}">
    <div class="lbl_bold" [ngStyle]="{'font-size': '98px'}">401</div>
    <div class="lbl_bold lbl_title">Link abgelaufen.</div>
</div>
<div *ngIf="invalidToken" class="reset_form" [ngStyle]="{'align-items': 'center'}">
    <div class="lbl_bold lbl_title">Not Authorized</div>
</div>
  