import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  public submitted = false;
  public isEdit = false;
  @Input() createUserForm: FormGroup;
  @Output() submitEmitter = new EventEmitter<undefined>();
  @Output() cancelEmitter = new EventEmitter<undefined>();

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    //this.onSubmit();
  }

  constructor() { }

  ngOnInit(): void {
    if(this.createUserForm.valid){
      this.isEdit = true;
    }
  }

  // get f() {
  //   return this.createUserForm.controls;
  // }

  // handleTyps(typ: TourTyp) {
  //   let isIncluded = false;
  //   for(let t of this.selectedTyps){
  //     if(typ.id == t.id){
  //       isIncluded = true;
  //       break;
  //     }
  //   }
  //   if(!isIncluded){
  //     this.selectedTyps.push(typ);
  //   } else {
  //     let index = this.selectedTyps.findIndex(t => t.id == typ.id);
  //     if(index > -1){
  //       this.selectedTyps.splice(index, 1);
  //     }
  //   }
  // }

  // isSelected(typ: TourTyp): boolean {
  //   for(let t of this.selectedTyps){
  //     if(typ.id == t.id){
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  // onSubmit() {
  //   this.submitted = true;
  //   this.f.typs.setValue(this.selectedTyps);
  //   if(this.createUserForm.invalid){
  //     return;
  //   } else if(this.f.typs.value.length == 0) {
  //     return;
  //   } else {
  //     this.submitEmitter.emit();
  //   }
  // }

  // cancel() {
  //   this.cancelEmitter.emit();
  // }
}
