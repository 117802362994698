<div id="list_container">
  <div class="list_header">
    <div class="input_container">
      <img class="icon_input" src="../../../assets/tasksearch.svg">
      <input #inputSearch type="text" placeholder="Suchen..." (input)="searchEmitter.emit(inputSearch.value)">
      <img class="reset_icon" (click)="resetSearchEmitter.emit(); inputSearch.value = ''" src="../../../../assets/xGray.svg">
    </div>
    <div class="list_info_wrapper">
      <div *ngIf="!loading" class="page_wrapper">
        <img id="arrow-left" src="../../../../assets/arrow-dark.svg" (click)="pageEmitter.emit(false)">
        <div id="count" class="lbl_regular">{{pageInfo}}</div>
        <img id="arrow-right" src="../../../../assets/arrow-dark.svg" (click)="pageEmitter.emit(true)">
      </div>
      <div *ngIf="loading" class="loading_container">
        <div class="loader"></div>
        <div style="margin-left: 10px;" class="lbl_regular">{{loadingDataAmount}} geladene Rezepte...</div>
      </div>
    </div>
  </div>
  <div id="list">
    <div class="list">
      <div class="lbl_medium">Name</div>
      <div class="lbl_medium">Kategorien</div>
      <div class="lbl_medium">Jahr</div>
      <div class="lbl_medium">Seite</div>
      <div class="lbl_medium">Magazin</div>
      <div class="lbl_medium">Beilage</div>
    </div>
    <div class="list_body">
        <div *ngIf="recipes.length == 0" class="empty_list lbl_regular">Keine Datensätze</div>
        <app-receipe-list-element *ngFor="let recipe of recipes | slice:sliceFrom:sliceTo; let last = last" [id]="'recipe_'+recipe.recipe_id"
            [last]="last"
            [recipe]="recipe"
            (editEmitter)="editEmitter.emit($event)"
            (deleteEmitter)="deleteEmitter.emit($event)"
        ></app-receipe-list-element>
    </div>
  </div>
</div>
