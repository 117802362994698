<div id="list">
    <div class="list_header">
        <div class="lbl_medium">Name</div>
        <div class="lbl_medium">E-Mail-Adresse</div>
        <div class="lbl_medium">Rundgang-Berechtigung</div>
        <div class="lbl_medium">Aktionen</div>
    </div>
    <div class="list_body">
        <app-user-list-element *ngFor="let user of users; let last = last"
            [last]="last"
            [user]="user"
            (editEmitter)="editEmitter.emit($event)"
            (resetEmitter)="resetEmitter.emit($event)"
            (deleteEmitter)="deleteEmitter.emit($event)"
        ></app-user-list-element>
    </div>
</div>