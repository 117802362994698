import { RecipeCategory } from './../../../buisnes-object/recipe/RecipeCategory';
import { Recipe } from './../../../buisnes-object/recipe/Recipe';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {
  @Input() categories: RecipeCategory[];
  @Output() editEmitter = new EventEmitter<RecipeCategory>();
  @Output() deleteEmitter = new EventEmitter<RecipeCategory>();
  @Output() searchEmitter = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }
}
