import { RecipeCategory } from '../../../buisnes-object/recipe/RecipeCategory';
import { FormGroup } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, OnDestroy, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-create-recipe',
  templateUrl: './create-recipe.component.html',
  styleUrls: ['./create-recipe.component.scss']
})
export class CreateRecipeComponent implements OnInit, AfterViewInit, OnDestroy {
  public submitted = false;
  public selectedCategories: RecipeCategory[] = [];
  public showDropOne = false;

  @ViewChild('dropdownOne') dropdownOne: ElementRef;
  public listener: () => void;

  @Input() form: FormGroup;
  @Input() categories: RecipeCategory[];
  @Input() isEdit: boolean;
  @Output() submitEmitter = new EventEmitter<undefined>();
  @Output() cancelEmitter = new EventEmitter<undefined>();

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  constructor(
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if(this.form.valid){
      this.selectedCategories = this.f.categories.value;
      this.isEdit = true;
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e: Event) => {
      if(this.showDropOne && !this.dropdownOne.nativeElement.contains(e.target)) this.showDropOne = false
    })
  }

  ngOnDestroy(): void {
    if(this.listener) this.listener();
  }

  get f() {
    return this.form.controls;
  }

  handleTyps(category: RecipeCategory) {
    let isIncluded = false;
    for(let cat of this.selectedCategories){
      if(cat.category_id == category.category_id){
        isIncluded = true;
        break;
      }
    }
    if(!isIncluded){
      this.selectedCategories.push(category);
    } else {
      let index = this.selectedCategories.findIndex(c => c.category_id == category.category_id);
      if(index > -1){
        this.selectedCategories.splice(index, 1);
      }
    }
  }

  isSelected(typ: RecipeCategory): boolean {
    for(let t of this.selectedCategories){
      if(typ.category_id == t.category_id){
        return true;
      }
    }
    return false;
  }

  onSubmit() {
    this.submitted = true;
    this.f.categories.setValue(this.selectedCategories);
    if(this.form.invalid){
      return;
    } else if(this.f.categories.value.length == 0) {
      return;
    } else {
      this.submitEmitter.emit();
    }
  }

  cancel() {
    this.cancelEmitter.emit();
  }
}
