<div class="menu_container">
  <div id="title_container" *ngIf="surface != 2">
      <div class="lbl_bold lbl_title">Rezepte</div>
      <div class="btn_submit" (click)="openCreate()">
        <div class="lbl_add"><span class="btn_submit_icon">+</span>Neues Rezept</div>
    </div>
  </div>
  <app-recipes-list *ngIf="surface == 1"
      [recipes]="receipsFiltered"
      [filter]="filter"
      [sliceTo]="sliceTo"
      [sliceFrom]="sliceFrom"
      [pageInfo]="pageInfo"
      [loading]="loading"
      [loadingDataAmount]="loadingDataAmount"
      (editEmitter)="openEdit($event)"
      (deleteEmitter)="deleteAction($event)"
      (pageEmitter)="pageHandling($event)"
      (searchEmitter)="searchAction($event)"
      (resetSearchEmitter)="resetSearch()"
  ></app-recipes-list>
  <app-create-recipe *ngIf="surface == 2"
      [form]="recipeForm"
      [categories]="categories"
      [isEdit]="isEdit"
      (cancelEmitter)="cancelCreate()"
      (submitEmitter)="createRecipe()"
  ></app-create-recipe>
</div>
