<div class="list list_element_memo" [ngStyle]="{'border-bottom-color': last ? 'transparent' : 'var(--seperator-color-light)'}">
  <div class="lbl_regular">{{recipe.recipe_name}}</div>
  <div class="cat_container">
    <div *ngFor="let category of recipe.categories; let last = last" class="lbl_regular">{{category.category_name}}{{!last ? ', ' : ''}}&nbsp;</div>
  </div>
  <div class="lbl_regular">{{recipe.year}}</div>
  <div class="lbl_regular">{{recipe.page}}</div>
  <div class="lbl_regular">{{recipe.magazine}}</div>
  <div class="lbl_regular">{{recipe.in_attachment ? 'Ja' : 'Nein'}}</div>
  <div class="btn_container">
      <img class="icon_details" src="../../../../../assets/navdots.svg" (click)="editEmitter.emit(recipe)">
      <img class="icon_details_delete" src="../../../../../assets/delete.svg" (click)="deleteEmitter.emit(recipe)">
  </div>
</div>
