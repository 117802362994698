<div class="view_container">
  <app-login-form *ngIf="showLogin"
    (showResetEmitter)="showLogin = false"
  ></app-login-form>
  <app-password-forgot-form *ngIf="!showLogin"
    (showLoginEmitter)="showLogin = true"
  ></app-password-forgot-form>
  <div id="login_image"></div>
</div>
<div id="logo_container" (click)="goToLink('https://software-entwicklung-graz.at/')">
  <div class="lbl_info logo_lbl">Powered by</div>
  <img id="logo_waymark" src="../../../../assets/waymarklogo.svg">
</div>
