import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/buisnes-object/user/User';

@Component({
  selector: 'app-user-list-element',
  templateUrl: './user-list-element.component.html',
  styleUrls: ['./user-list-element.component.scss']
})
export class UserListElementComponent implements OnInit {
  @Input() user: User;
  @Input() last: boolean;
  @Output() editEmitter = new EventEmitter<User>();
  @Output() resetEmitter = new EventEmitter<User>();
  @Output() deleteEmitter = new EventEmitter<User>();

  constructor() { }

  ngOnInit(): void {
  }

  editUser() {
    this.editEmitter.emit(this.user);
  }
  
  resetPassword() {
    this.resetEmitter.emit(this.user);
  }

  deleteUser() {
    this.deleteEmitter.emit(this.user);
  }
}
