import { Recipe } from './../../../buisnes-object/recipe/Recipe';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageHandler } from 'src/app/helper/PagerHandler';

@Component({
  selector: 'app-recipes-list',
  templateUrl: './recipes-list.component.html',
  styleUrls: ['./recipes-list.component.scss']
})
export class RecipesListComponent implements OnInit {
  @Input() recipes: Recipe[];
  @Input() pageHandler: PageHandler;
  @Input() pageInfo: string;
  @Input() sliceFrom: number;
  @Input() sliceTo: number;
  @Input() loadingDataAmount: number;
  @Input() loading: number;
  @Output() editEmitter = new EventEmitter<Recipe>();
  @Output() deleteEmitter = new EventEmitter<Recipe>();
  @Output() pageEmitter = new EventEmitter<boolean>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() resetSearchEmitter = new EventEmitter<undefined>();

  constructor() { }

  ngOnInit(): void {
  }
}
