import { Router } from '@angular/router';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  public showPassword = false;
  public submitted = false;
  public accessDenied = false;
  public loginForm: FormGroup;

  @Output() showResetEmitter = new EventEmitter<undefined>();

  @HostListener('document:keydown.enter', ['$event'])onEnterHandler(event: KeyboardEvent) {
    this.onSubmit();
  }

  constructor(
    private formbuilder: FormBuilder,
    private lService: LoginService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.buildFormGroup();
  }

  buildFormGroup() {
    this.loginForm = this.formbuilder.group({
      username: ['',[Validators.required, Validators.email]],
      password: ['',Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;
    if(this.loginForm.invalid){
      return;
    } else {
      this.lService.login(this.loginForm.controls.username.value, this.loginForm.controls.password.value).subscribe((access) => {
        if(access){
          this.router.navigate(['/recipes']);
        } else {
          this.accessDenied = true;
        }
      })
    }
  }

  toReset() {
    this.showResetEmitter.emit();
  }
}
