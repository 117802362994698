import { RecipeCategory } from './../../../../buisnes-object/recipe/RecipeCategory';
import { Recipe } from './../../../../buisnes-object/recipe/Recipe';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-categorie-list-element',
  templateUrl: './categorie-list-element.component.html',
  styleUrls: ['./categorie-list-element.component.scss']
})
export class CategorieListElementComponent implements OnInit {
  @Input() category: RecipeCategory;
  @Input() last: boolean;
  @Output() editEmitter = new EventEmitter<RecipeCategory>();
  @Output() deleteEmitter = new EventEmitter<RecipeCategory>();

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
