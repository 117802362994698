import { RecipeCategory } from './../../buisnes-object/recipe/RecipeCategory';
import { catchError, map } from 'rxjs/operators';
import { RecipeFactory } from './../../buisnes-object/recipe/factory/RecipeFactory';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Recipe } from './../../buisnes-object/recipe/Recipe';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import { DialogService } from '../dialog/dialog.service';
import { RecipeCategoryFactory } from 'src/app/buisnes-object/recipe/factory/RecipeCategoryFactory';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public getCategories(): Observable<RecipeCategory[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/category', {headers});
    return observable.pipe(
      map((result: any) => {
        let data = RecipeCategoryFactory.jsonFactory(result.categories);
        return data;
      }),
      catchError(error => {
        if(error.status == 403 || error.status == 405){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createCategrorie(category: RecipeCategory): Observable<RecipeCategory> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.post(environment.api + '/back/category', {
      category_name: category.category_name,
    }, {headers});
    return observable.pipe(
      map((result: any) => {
        let data = RecipeCategoryFactory.jsonFactoryOne(result.category);
        return data;
      }),catchError(error => {
        if(error.status == 403 || error.status == 405) {
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_code == 107 ? 'Fehler durch doppelten Eintrag.' : error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateCategrorie(category: RecipeCategory): Observable<RecipeCategory> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.put(environment.api + '/back/category', {
      category_id: category.category_id,
      category_name: category.category_name,
    }, {headers});
    return observable.pipe(
      map((result: any) => {
        let data = RecipeCategoryFactory.jsonFactoryOne(result.category);
        return data;
      }),catchError(error => {
        if(error.status == 403 || error.status == 405) {
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_code == 107 ? 'Fehler durch doppelten Eintrag.' : error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteCategory(id: number): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/back/category/' + id, {headers});
    return observable.pipe(
      map((result: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403 || error.status == 405){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }
}
