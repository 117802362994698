import { RecipeCategory } from './RecipeCategory';
export class Recipe {
  constructor(
    public recipe_id: number,
    public recipe_name: string,
    public magazine: number,
    public year: number,
    public page: number,
    public in_attachment: boolean,
    public categories: RecipeCategory[]
  ){}
}
