import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/service/login/login.service';

@Component({
  selector: 'app-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public showPasswordOne = false;
  public showPasswordTwo = false;
  public submitted = false;
  public successChanged = false;
  public invalidToken = false;
  public tokenExpired = false;
  public notIdent = false;
  public token: string;

  constructor(
    private formBuilder: FormBuilder,
    private lService: LoginService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      passwordOne: ['',[Validators.required, Validators.minLength(8)]],
      passwordTwo: ['',[Validators.required, Validators.minLength(8)]],
    });
    this.route.queryParams.subscribe((params) => {
      if(!params.token){
        this.invalidToken = true;
      } else {
        this.token = params.token;
      }
    })
  }

  onSubmit() {
    this.submitted = true;
    if(this.resetPasswordForm.invalid){
      return;
    } else if(this.resetPasswordForm.controls.passwordOne.value != this.resetPasswordForm.controls.passwordTwo.value) {
      this.notIdent = true;
      return;
    } else {
      this.lService.resetPassword(this.token, this.resetPasswordForm.controls.passwordOne.value).subscribe((success) => {
        if(success){
          this.successChanged = true;
        } else {
          this.tokenExpired = true;
        }
      })
    }
  }

  toLogin() {
    this.router.navigate(['login']);
  }
}
