import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'koch-und-back-journal';

  showMenu(outlet: RouterOutlet): boolean {
    if(!outlet?.activatedRouteData?.isLogin){
      return true;
    }
    return false;
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }
}
