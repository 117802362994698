<div class="menu_container">
  <div id="title_container">
      <div class="lbl_bold lbl_title">Kategorien</div>
      <!-- <div class="btn_submit" (click)="openCreate()">
        <div class="lbl_add"><span class="btn_submit_icon">+</span>Neue Kategorie</div>
      </div> -->
  </div>
  <div class="body">
    <app-categories-list
    [categories]="categoriesFiltered"
    (editEmitter)="openEdit($event)"
    (deleteEmitter)="deleteAction($event)"
    (searchEmitter)="search($event)"
    ></app-categories-list>
    <app-create-categorie
    [form]="categoryForm"
    [isEdit]="isEdit"
    (cancelEmitter)="cancelCreate()"
    (submitEmitter)="createRecipe()"
    ></app-create-categorie>
  </div>
</div>
