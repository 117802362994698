import { catchError } from 'rxjs/operators';
import { logging } from 'protractor';
import { RecipeCategory } from './../../buisnes-object/recipe/RecipeCategory';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Recipe } from './../../buisnes-object/recipe/Recipe';
import { DialogService } from './../../service/dialog/dialog.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { forkJoin, Subscription, of } from 'rxjs';
import { RecipeService } from 'src/app/service/recipe/recipe.service';
import { CategoryService } from 'src/app/service/categroy/category.service';
import { PageHandler } from 'src/app/helper/PagerHandler';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit, OnDestroy {
  public receips: Recipe[] = [];
  public receipsFiltered: Recipe[] = [];
  public currentReceipsFiltered: Recipe[] = [];
  public recipeForm: FormGroup;
  public selectedRecipe: Recipe;
  public categories: RecipeCategory[] = [];
  public surface = 1;
  public dialogQuerySubsription: Subscription;
  public isEdit = false;
  public pageHandler: PageHandler;
  public pageInfo: string;
  public sliceTo: number;
  public sliceFrom: number;
  public loadingDataAmount = 0;
  public loading = true;
  public limit = 1000;
  public offset = 0;
  public maxAmountData = 18000;

  constructor(
    private rService: RecipeService,
    private cService: CategoryService,
    private formBuilder: FormBuilder,
    private dService: DialogService,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.getDateServerside();
    this.setSubscriptions();
    this.createForm();
  }

  getDateServerside() {
    this.receips = this.rService.recipes$.getValue() || [];
    if(this.receips.length == 0){
      let promises: Promise<any>[] = [];
      for(let i = 0; i <= this.maxAmountData; i+=1000){
        promises.push(this.getRecipes(this.limit, i));
      }
      Promise.all(promises).then(() => {
        this.loading = false;
        this.sortRecipes();
        this.setPages();
        this.rService.recipes$.next(this.receips);
      })
    } else {
      this.loading = false;
      this.receipsFiltered = this.receips;
      this.sortRecipes();
      this.setPages();
    }
    this.cService.getCategories().subscribe((result) => {
      if(result){
        this.categories = result;
        this.sortCategories();
      }
    })
  }

  ngOnDestroy(): void {
    this.dialogQuerySubsription.unsubscribe();
  }

  setPages() {
    this.pageHandler = new PageHandler(250, this.receipsFiltered.length);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  async pageHandling(next: boolean) {
    this.pageHandler.action(next);
    this.sliceFrom = this.pageHandler.sliceFrom;
    this.sliceTo = this.pageHandler.sliceTo;
    this.pageInfo = this.pageHandler.getLabel();
  }

  sortRecipes() {
    this.receipsFiltered.sort((a,b) => {
      if(a.recipe_name < b.recipe_name) return -1;
      if(a.recipe_name > b.recipe_name) return 1;
      return 0;
    });
  }

  getRecipes(limit: number, offset: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let i = 0;
      this.rService.getRecipes(limit, offset).subscribe((result) => {
        if(result){
          this.receips = this.receips.concat(result);
          this.loadingDataAmount = this.receips.length;
          this.receipsFiltered = this.receips;
          this.sortRecipes();
          resolve(true);
        }
      })
    })
  }

  sortCategories() {
    this.categories.sort((a,b) => {
      if(a.category_name < b.category_name) return -1;
      if(a.category_name > b.category_name) return 1;
      return 0;
    });
  }

  setSubscriptions() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete'){
          this.deleteRecipe(value.submit_value);
        }
      }
    });
  }

  createForm() {
    this.recipeForm = this.formBuilder.group({
      recipe_name: ['', Validators.required],
      magazine: [null, Validators.required],
      year: [null, [Validators.required, Validators.max(9999)]],
      page: [null, Validators.required],
      in_attachment: [null, Validators.required],
      categories: [null, Validators.required],
    })
  }

  get f() {
    return this.recipeForm.controls;
  }

  fillForm() {
    this.recipeForm.reset();
    this.f.recipe_name.setValue(this.selectedRecipe.recipe_name);
    this.f.magazine.setValue(this.selectedRecipe.magazine);
    this.f.year.setValue(this.selectedRecipe.year);
    this.f.page.setValue(this.selectedRecipe.page);
    this.f.in_attachment.setValue(this.selectedRecipe.in_attachment);
    this.f.categories.setValue(this.selectedRecipe.categories);
  }

  openCreate() {
    this.recipeForm.reset();
    this.surface = 2;
  }

  cancelCreate() {
    this.recipeForm.reset();
    this.surface = 1;
  }

  createRecipe() {
    let recipe = new Recipe(
      this.isEdit ? this.selectedRecipe.recipe_id : 0,
      this.f.recipe_name.value,
      this.f.magazine.value,
      this.f.year.value,
      this.f.page.value,
      this.f.in_attachment.value,
      this.f.categories.value,
    );
    if(!this.isEdit){
      this.rService.createRecipe(recipe).subscribe((response) => {
        if(response){
          this.receips.push(response);
          this.receipsFiltered = this.receips;
          this.rService.recipes$.next(this.receips);
          this.setPages();
          this.sortRecipes();
          this.recipeForm.reset();
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Rezept angelegt',
            success: true
          });
          this.surface = 1;
        }
      })
    } else {
      this.rService.updateRecipe(recipe).subscribe((response) => {
        if(response){
          let index = this.receips.findIndex(r => r.recipe_id == response.recipe_id);
          if(index > -1) this.receips[index] = response;
          index = this.receipsFiltered.findIndex(r => r.recipe_id == response.recipe_id);
          if(index > -1) this.receipsFiltered[index] = response;
          this.rService.recipes$.next(this.receips);
          this.sortRecipes();
          this.recipeForm.reset();
          this.isEdit = false;
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Rezept aktualisiert',
            success: true
          });
          this.surface = 1;
        }
      })
    }
  }

  openEdit(recipe: Recipe) {
    this.selectedRecipe = recipe;
    this.fillForm();
    this.isEdit = true;
    this.surface = 2;
  }

  deleteAction(recipe: Recipe) {
    this.dService.openQuery(
      {
        title: 'Rezept wirklich löschen?',
        message: 'Sind sie sicher, dass Sie das Rezept unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete',
        submit_value: recipe.recipe_id,
      }
    );
  }

  deleteRecipe(id: number) {
    this.rService.deleteRecipe(id).subscribe((success) => {
      if(success){
        let index = this.receips.findIndex(r => r.recipe_id == id);
        if(index > -1){
          this.receips.splice(index, 1);
        }
        this.receipsFiltered = this.receips;
        this.rService.recipes$.next(this.receips);
        this.setPages();
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Rezept entfernt',
          success: true
        });
      }
    })
  }

  searchAction(value: string) {
    this.receipsFiltered = [];
    if(value && value.length > 0){
      this.receipsFiltered = this.receips.filter((recipe) => {
        return recipe.recipe_name.toLowerCase().includes(value.toLowerCase()) ||
               recipe.recipe_name.toLowerCase().includes(value.toLowerCase()) ||
               this.hasCategory(recipe, value);
      })
    } else {
      this.receipsFiltered = this.receips;
    }
    this.setPages();
  }

  hasCategory(recipe: Recipe, value: string): boolean {
    for(let categorie of recipe.categories){
      if(categorie.category_name.toLowerCase().includes(value.toLowerCase())){
        return true;
      }
    }
    return false;
  }

  resetSearch() {
    this.receipsFiltered = this.receips;
    this.setPages();
  }
}
