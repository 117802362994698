import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingComponent } from './view/loading/loading.component';
import { LoginComponent } from './view/login/login.component';
import { LoginFormComponent } from './view/login/login-form/login-form.component';
import { DialogQueryComponent } from './view/dialog/dialog-query/dialog-query.component';
import { PasswordForgotFormComponent } from './view/login/password-forgot-form/password-forgot-form.component';
import { ResetPasswordComponent } from './view/reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './view/reset-password/reset-password-form/reset-password-form.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoadingScreenInterceptor } from './service/loading/LoadingInterceptor';
import { MenuComponent } from './view/menu/menu.component';
import { UserListComponent } from './view/user-administration/user-list/user-list.component';
import { UserListElementComponent } from './view/user-administration/user-list/user-list-element/user-list-element.component';
import { UserCreateComponent } from './view/user-administration/user-create/user-create.component';
import { DialogSuccessComponent } from './view/dialog/dialog-success/dialog-success.component';
import { DialogNotificationComponent } from './view/dialog/dialog-notification/dialog-notification.component';
import { RecipesComponent } from './view/recipes/recipes.component';
import { CategoriesComponent } from './view/categories/categories.component';
import { RecipesListComponent } from './view/recipes/recipes-list/recipes-list.component';
import { ReceipeListElementComponent } from './view/recipes/recipes-list/receipe-list-element/receipe-list-element.component';
import { CreateRecipeComponent } from './view/recipes/create-recipe/create-recipe.component';
import { CategoriesListComponent } from './view/categories/categories-list/categories-list.component';
import { CategorieListElementComponent } from './view/categories/categories-list/categorie-list-element/categorie-list-element.component';
import { CreateCategorieComponent } from './view/categories/create-categorie/create-categorie.component';
import '@angular/common/locales/global/de';

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    LoginComponent,
    LoginFormComponent,
    DialogQueryComponent,
    PasswordForgotFormComponent,
    ResetPasswordComponent,
    ResetPasswordFormComponent,
    MenuComponent,
    UserListComponent,
    UserListElementComponent,
    UserCreateComponent,
    DialogSuccessComponent,
    DialogNotificationComponent,
    RecipesComponent,
    CategoriesComponent,
    RecipesListComponent,
    ReceipeListElementComponent,
    CreateRecipeComponent,
    CategoriesListComponent,
    CategorieListElementComponent,
    CreateCategorieComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-at'
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
