<div class="menu_container">
  <div id="header">
      <div class="lbl_title lbl_bold">{{isEdit ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}}</div>
  </div>
  <form [formGroup]="form">
      <div class="flex">
          <div class="data_container">
              <div class="lbl_medium">Name</div>
              <input class="inputfield" [ngClass]="submitted && f.category_name.invalid ? 'inputfield_invalid' : ''" type="text" formControlName="category_name" tabindex="1">
          </div>
          <div *ngIf="isEdit" class="btn_lbl" (click)="cancel()">Abbrechen</div>
          <div class="btn_submit" (click)="onSubmit()">{{isEdit ? 'Speichern' : 'Erstellen'}}</div>
      </div>
  </form>
</div>
