import { RecipeCategory } from './../RecipeCategory';
export class RecipeCategoryFactory {
  static jsonFactory(rawBody: any): RecipeCategory[] {
    let data: RecipeCategory[] = [];
    for(let raw of rawBody){
        data.push(this.jsonFactoryOne(raw));
    }
    return data;
}

  static jsonFactoryOne(rawBody: any): RecipeCategory {
      return new RecipeCategory(
          rawBody.category_id,
          rawBody.category_name,
      );
  }
}
