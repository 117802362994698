import { RecipeCategory } from './../../buisnes-object/recipe/RecipeCategory';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Recipe } from './../../buisnes-object/recipe/Recipe';
import { DialogService } from './../../service/dialog/dialog.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { RecipeService } from 'src/app/service/recipe/recipe.service';
import { CategoryService } from 'src/app/service/categroy/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  public categories: RecipeCategory[] = [];
  public categoriesFiltered: RecipeCategory[] = [];
  public selectedCategory: RecipeCategory;
  public categoryForm: FormGroup;
  public dialogQuerySubsription: Subscription;
  public isEdit = false;

  constructor(
    private cService: CategoryService,
    private formBuilder: FormBuilder,
    private dService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getDateServerside();
    this.setSubscriptions();
    this.createForm();
  }

  getDateServerside() {
    forkJoin([
      this.cService.getCategories()
    ]).subscribe(([categories]:[RecipeCategory[]]) => {
      if(categories){
        this.categories = categories;
        this.categoriesFiltered = categories;
        this.sortCategories();
      }
    });
  }

  ngOnDestroy(): void {
    this.dialogQuerySubsription.unsubscribe();
  }

  sortCategories() {
    this.categoriesFiltered.sort((a,b) => {
      if(a.category_name < b.category_name) return -1;
      if(a.category_name > b.category_name) return 1;
      return 0;
    });
  }

  setSubscriptions() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete'){
          this.deleteCategory(value.submit_value);
        }
      }
    });
  }

  createForm() {
    this.categoryForm = this.formBuilder.group({
      category_name: ['', Validators.required],
    })
  }

  get f() {
    return this.categoryForm.controls;
  }

  fillForm() {
    this.categoryForm.reset();
    this.f.category_name.setValue(this.selectedCategory.category_name);
  }

  openCreate() {
    this.categoryForm.reset();
  }

  cancelCreate() {
    this.categoryForm.reset();
    this.isEdit = false;
  }

  createRecipe() {
    let category = new RecipeCategory(
      this.isEdit ? this.selectedCategory.category_id : 0,
      this.f.category_name.value,
    );
    if(!this.isEdit){
      this.cService.createCategrorie(category).subscribe((response) => {
        if(response){
          this.categories.push(response);
          this.sortCategories();
          this.categoryForm.reset();
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Kategorie angelegt',
            success: true
          });
          setTimeout(() => {
            let doc = document.getElementById('category_'+response.category_id);
            if(doc) doc.scrollIntoView({block: "end", behavior: "smooth"});
          }, 200);
        }
      })
    } else {
      this.cService.updateCategrorie(category).subscribe((response) => {
        if(response){
          let index = this.categories.findIndex(cat => cat.category_id == response.category_id);
          if(index > -1) this.categories[index] = response;
          this.sortCategories();
          this.categoryForm.reset();
          this.isEdit = false;
          this.dService.showNotification({
            title: 'Erfolgreich',
            message: 'Kategorie aktualisiert',
            success: true
          });
          setTimeout(() => {
            let doc = document.getElementById('category_'+response.category_id);
            if(doc) doc.scrollIntoView({block: "end", behavior: "smooth"});
          }, 200);
        }
      })
    }
  }

  openEdit(category: RecipeCategory) {
    this.isEdit = true;
    this.selectedCategory = category;
    this.fillForm();
  }

  deleteAction(category: RecipeCategory) {
    this.dService.openQuery(
      {
        title: 'Kategorie wirklich löschen?',
        message: 'Sind sie sicher, dass Sie die Kategorie unwiderruflich löschen möchten?',
        btn_cancel_txt: 'Abbrechen',
        btn_submit_txt: 'Löschen',
        typ: 'delete',
        submit_value: category.category_id,
      }
    );
  }

  deleteCategory(id: number) {
    this.cService.deleteCategory(id).subscribe((success) => {
      if(success){
        let index = this.categories.findIndex(t => t.category_id == id);
        if(index > -1){
          this.categories.splice(index, 1);
        }
        this.categoriesFiltered = this.categories;
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Kategorie entfernt',
          success: true
        });
      }
    })
  }

  search(value: string) {
    this.categoriesFiltered = [];
    if(value && value.length > 0){
      this.categoriesFiltered = this.categories.filter((category) => {
        return category.category_name.toLowerCase().includes(value.toLowerCase()) ||
               category.category_id.toString().toLowerCase().includes(value.toLowerCase());
      })
    } else {
      this.categoriesFiltered = this.categories;
    }
  }
}
