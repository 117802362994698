import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-success',
  templateUrl: './dialog-success.component.html',
  styleUrls: ['./dialog-success.component.scss']
})
export class DialogSuccessComponent implements OnInit, OnDestroy {
  public show = false;
  public message: string;
  public title: string;
  public subscription: Subscription;

  constructor(
    private dService : DialogService
  ) {
    // this.subscription = this.dService.openSuccess$.subscribe((data: any) => {
    //   this.title = data.title;
    //   this.message = data.message;
    //   this.show = true;
    //   setTimeout(() => {
    //     this.show = false;
    //   }, 4000);
    // });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
