<div class="menu_container">
  <div id="header">
      <div class="lbl_title lbl_bold">Neues Rezept erstellen</div>
      <div class="btn_container">
          <div class="btn_lbl lbl_italic" (click)="cancel()">Abbrechen</div>
          <div class="btn_submit" (click)="onSubmit()">{{isEdit ? 'Speichern' : 'Erstellen'}}</div>
      </div>
  </div>
  <form id="body" [formGroup]="form">
      <div id="column_one">
          <div class="data_container">
              <div class="lbl_medium">Name</div>
              <input class="inputfield" [ngClass]="submitted && f.recipe_name.invalid ? 'inputfield_invalid' : ''" type="text" formControlName="recipe_name" tabindex="1">
          </div>
          <div class="grid_small">
            <div class="data_container">
              <div class="lbl_medium">Magazin</div>
              <input class="inputfield" [ngClass]="submitted && f.magazine.invalid ? 'inputfield_invalid' : ''" type="number" min="0" formControlName="magazine" tabindex="3">
            </div>
            <div class="data_container">
              <div class="lbl_medium">Jahr</div>
              <input class="inputfield" [ngClass]="submitted && f.year.invalid ? 'inputfield_invalid' : ''" type="number" min="0" max="9999" formControlName="year" tabindex="3">
            </div>
          </div>
          <div class="grid_small">
            <div class="data_container">
              <div class="lbl_medium">Seite</div>
              <input class="inputfield" [ngClass]="submitted && f.page.invalid ? 'inputfield_invalid' : ''" type="number" min="0" formControlName="page" tabindex="3">
            </div>
            <div class="data_container">
              <div class="flex">
                <div class="lbl_medium">Anhang</div>
                <div class="lbl_invalid" *ngIf="submitted && f.in_attachment.value == null">Einen Wert auswählen.</div>
              </div>
              <div #dropdownOne class="dropdown_container">
                <div class="dropdown_header" [ngClass]="showDropOne ? 'dropdown_header_active' : ''" (click)="showDropOne = !showDropOne">
                    <div class="dropdown_header_lbl lbl_regular">{{f.in_attachment.value == true ? 'Ja' : (f.in_attachment.value == false ? 'Nein' : '---')}}</div>
                    <img class="icon_dropdown icon_dropdown_arrow" [ngClass]="showDropOne ? 'icon_dropdown_arrow_rotate' : ''" src="../../../assets/arrow.svg">
                </div>
                <div class="dropdown_body" [ngClass]="showDropOne ? 'dropdown_body_open' : ''">
                    <div class="dropdown_body_item lbl_regular" (click)="f.in_attachment.setValue(true); this.showDropOne = false">Ja</div>
                    <div class="dropdown_body_item lbl_regular" (click)="f.in_attachment.setValue(false); this.showDropOne = false">Nein</div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div id="column_two">
          <div class="data_container">
              <div class="flex">
                  <div class="lbl_medium">Kategorien</div>
                  <div class="lbl_invalid" *ngIf="submitted && selectedCategories.length == 0">Es muss min. eine Kategorie ausgewählt werden.</div>
              </div>
              <div class="checkbox_wrapper">
                  <div class="checkbox_container" *ngFor="let category of categories" (click)="handleTyps(category)">
                      <div class="checkboxi" [ngClass]="isSelected(category) ? 'checkboxi_checked' : 'checkboxi_unchecked'"></div>
                      <div class="lbl_regular checkbox_lbl">{{category.category_name}}</div>
                  </div>
              </div>
          </div>
      </div>
  </form>
</div>
