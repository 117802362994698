import { catchError, map } from 'rxjs/operators';
import { RecipeFactory } from './../../buisnes-object/recipe/factory/RecipeFactory';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Recipe } from './../../buisnes-object/recipe/Recipe';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import { DialogService } from '../dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {
  public recipes$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
  ) { }

  public getRecipes(limit: number, offset: number): Observable<Recipe[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.get(environment.api + '/back/recipe?limit='+ limit +'&offset=' + offset, {headers});
    return observable.pipe(
      map((result: any) => {
        let data = RecipeFactory.jsonFactory(result.recipes);
        return data;
      }),
      catchError(error => {
        if(error.status == 403 || error.status == 405){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehler',
            message: error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public createRecipe(recipe: Recipe): Observable<Recipe> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let categories: any[] = [];
    recipe.categories.forEach(element => {
      categories.push({
        category_id: element.category_id
      })
    });
    const observable = this.http.post(environment.api + '/back/recipe',
    {
      recipe_name: recipe.recipe_name,
      year: recipe.year,
      magazine: recipe.magazine,
      page: recipe.page,
      in_attachment: recipe.in_attachment,
      categories: categories
    }, {headers});
    return observable.pipe(
      map((result: any) => {
        let data = RecipeFactory.jsonFactoryOne(result.recipe);
        return data;
      }),catchError(error => {
        if(error.status == 403 || error.status == 405) {
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_code == 107 ? 'Fehler durch doppelten Eintrag.' : error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public updateRecipe(recipe: Recipe): Observable<Recipe> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    let categories: any[] = [];
    recipe.categories.forEach(element => {
      categories.push({
        category_id: element.category_id
      })
    });
    const observable = this.http.put(environment.api + '/back/recipe',
    {
      recipe_id: recipe.recipe_id,
      recipe_name: recipe.recipe_name,
      year: recipe.year,
      magazine: recipe.magazine,
      page: recipe.page,
      in_attachment: recipe.in_attachment,
      categories: categories
    }, {headers});
    return observable.pipe(
      map((result: any) => {
        let data = RecipeFactory.jsonFactoryOne(result.recipe);
        return data;
      }),catchError(error => {
        if(error.status == 403 || error.status == 405) {
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_code == 107 ? 'Fehler durch doppelten Eintrag.' : error.error.error_message,
            success: false
          });
        }
        return [];
      })
    );
  }

  public deleteRecipe(id: number): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token')
    });
    const observable = this.http.delete(environment.api + '/back/recipe/' + id, {headers});
    return observable.pipe(
      map((result: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403 || error.status == 405){
          this.lService.logout();
        } else {
          console.log(error);
          this.dService.showNotification({
            title: 'Fehlgeschlagen',
            message: error.error.error_message,
            success: false
          });
        }
        return [false];
      })
    );
  }
}
