import { RecipeCategoryFactory } from './RecipeCategoryFactory';
import { Recipe } from './../Recipe';
export class RecipeFactory {
  static jsonFactory(rawBody: any): Recipe[] {
    let data: Recipe[] = [];
    for(let raw of rawBody){
        data.push(this.jsonFactoryOne(raw));
    }
    return data;
}

  static jsonFactoryOne(rawBody: any): Recipe {
      return new Recipe(
          rawBody.recipe_id,
          rawBody.recipe_name,
          rawBody.magazine,
          rawBody.year,
          rawBody.page,
          rawBody.in_attachment,
          RecipeCategoryFactory.jsonFactory(rawBody.categories)
      );
  }
}
