import { Recipe } from './../../../../buisnes-object/recipe/Recipe';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-receipe-list-element',
  templateUrl: './receipe-list-element.component.html',
  styleUrls: ['./receipe-list-element.component.scss']
})
export class ReceipeListElementComponent implements OnInit {
  @Input() recipe: Recipe;
  @Input() last: boolean;
  @Output() editEmitter = new EventEmitter<Recipe>();
  @Output() deleteEmitter = new EventEmitter<Recipe>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  openTour() {
  }
}
