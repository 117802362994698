import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-notification',
  templateUrl: './dialog-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss']
})
export class DialogNotificationComponent implements OnInit {
  public show = false;
  public isSuccess = false;
  public message: string;
  public title: string;
  public subscription: Subscription;

  constructor(
    private dService : DialogService
  ) {
    this.subscription = this.dService.notification$.subscribe((data: any) => {
      this.title = data.title;
      this.message = data.message;
      this.isSuccess = data.success;
      this.show = true;
      setTimeout(() => {
        this.show = false;
      }, 4000);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
