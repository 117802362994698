<form id="login_form" [formGroup]="loginForm">
  <div class="lbl_bold lbl_title">Login</div>
  <div [ngStyle]="{'opacity': accessDenied ? '1' : '0'}" class="lbl_invalid">E-Mail oder Passwort falsch.</div>
  <div class="input_container" [ngClass]="submitted && loginForm.controls.username.invalid ? 'input_container_invalid' : ''">
    <img class="icon" src="../../../../assets/loginuser.svg">
    <input type="text" placeholder="E-Mail" formControlName="username" (ngModelChange)="accessDenied = false">
  </div>
  <div class="input_container" [ngClass]="submitted && loginForm.controls.password.invalid ? 'input_container_invalid' : ''">
    <img class="icon" src="../../../../assets/loginpassword.svg">
    <input type="{{showPassword ? 'text' : 'password'}}" placeholder="Passwort" formControlName="password" (ngModelChange)="accessDenied = false">
    <img (click)="showPassword = !showPassword" class='icon_eye' [src]="showPassword ? '../../../../assets/eyeShow.svg' : '../../../../assets/eyeHide.svg'">
  </div>
  <div class="btn_container_horizontal">
    <!-- <div class="lbl_italic btn_lbl" (click)="toReset()">Passwort vergessen</div> -->
    <div class="btn_submit" (click)="onSubmit()">Login</div>
  </div>
</form>
