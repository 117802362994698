<div id="dialog_window" *ngIf="show">
    <div id="dialog_container">
        <div class="lbl_bold">{{title}}</div>
        <div class="lbl_regular message">{{message}}</div>
        <div class="btn_container">
            <div class="btn_lbl" (click)="show=false">{{btnCancelTxt}}</div>
            <div class="btn_submit" (click)="submit()">{{btnSubmitTxt}}</div>
        </div>
    </div>
  </div>
  