<div class="list_element" [ngStyle]="{'border-bottom-color': last ? 'transparent' : 'var(--seperator-color-light)'}">
    <div class="lbl_regular">{{user.lastName}} {{user.firstName}}</div>
    <div class="lbl_regular">{{user.email}}</div>
    <div class="boxes">
        <div *ngFor="let typ of user.typs" class="lbl_box lbl_regular" style="margin-bottom: 5px; padding: 5px 10px;">{{typ.name}}</div>
    </div>
    <div class="action_container">
        <div class="action" (click)="editUser()">
            <img class="icon_action" src="../../../../../assets/edit.svg">
            <div class="lbl_italic">bearbeiten</div>
        </div>
        <div class="action" (click)="resetPassword()">
            <img class="icon_action" src="../../../../../assets/redo.svg">
            <div class="lbl_italic">Passwort zurücksetzen</div>
        </div>
        <div class="action" (click)="deleteUser()">
            <img class="icon_action" src="../../../../../assets/deletered.svg">
            <div class="lbl_italic">löschen</div>
        </div>
    </div>
</div>
