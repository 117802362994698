<div id="app_container">
  <app-menu *ngIf="showMenu(outlet)"></app-menu>
  <router-outlet #outlet="outlet"></router-outlet>
  <div id="footer" *ngIf="showMenu(outlet)" (click)="goToLink('https://software-entwicklung-graz.at/')">
    <div class="lbl_regular logo_lbl">Powered by</div>
    <img id="logo_waymark" src="../../../../assets/waymarklogo.svg">
  </div>
  <app-loading></app-loading>
  <app-dialog-notification></app-dialog-notification>
  <app-dialog-query></app-dialog-query>
</div>
