import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-query',
  templateUrl: './dialog-query.component.html',
  styleUrls: ['./dialog-query.component.scss']
})
export class DialogQueryComponent implements OnInit {
  public show = false;
  public title: string;
  public message: string;
  public btnCancelTxt: string;
  public btnSubmitTxt: string;
  public submitValue: any;
  public typ: any;
  public subscription: Subscription;

  constructor(
    private dialogService: DialogService
  ) {
    this.subscription = this.dialogService.openDialogQuery$.subscribe((data: any) => {
      this.show = true;
      this.title = data.title;
      this.message = data.message;
      this.btnCancelTxt = data.btn_cancel_txt;
      this.btnSubmitTxt = data.btn_submit_txt;
      this.submitValue = data.submit_value;
      this.typ = data.typ;
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

  submit(){
    this.show = false;
    this.dialogService.closeQuery(
      {
        submit_value: this.submitValue,
        typ: this.typ
      }
    );
  }
}
